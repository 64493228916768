@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

/* Apply Lato for general body text */
body {
  font-family: 'Lato', sans-serif;
  font-weight: 300; /* Less bold, lighter weight */
}

/* Text styling */
.text-content h1,
.text-content h2,
.text-content h3,
.text-content h4,
.text-content h5,
.text-content h6 {
  margin-top: 0;
  font-family: 'Cinzel', serif;
  font-weight: 400; /* Regular weight for headings */
}

.text-content p {
  font-family: 'Lato', sans-serif;
  font-weight: 400; /* Lighter weight for better readability */
  font-size: 1.125rem; /* Adjust as necessary */
  line-height: 1.6;
  color: #666;
}

.large-font {
  font-size: 4rem;
}

.text-content a {
  color: #008080;
  text-decoration: none;
}

.text-content a:hover {
  color: #006666;
}

.text-content a:active {
  color: #004c4c;
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.text-content h1 {
  font-size: 2.5rem; /* Heading size */
  color: #333;
}

/* Parallax Background Styling */
.parallax-background {
  background-image: url('../public/background/la_susana_paisaje_combined.webp'); /* Path to your image */
  background-attachment: fixed; /* Fixed background for parallax effect */
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
}

/* Foreground Content Styling */
.foreground-content {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

/* Navigation bar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 1rem 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-bottom: 0.125rem solid black;
  position: relative;
}

.navbar-title {
  font-size: 3.5rem;
}

.no-link {
  color: inherit; /* Makes the link inherit the color from its parent */
  text-decoration: none; /* Removes the underline */
}

.tangerine-regular {
  font-family: "Tangerine", cursive;
  font-weight: 400;
  font-style: normal;
}

.tangerine-bold {
  font-family: "Tangerine", cursive;
  font-weight: 800;
  font-style: normal;
}

.navbar-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.navbar-links a {
  color: #333;
  text-decoration: none;
}

.navbar-links a:hover {
  color: #006666; /* Slightly darker teal */
}

.navbar-links a:active {
  color: #004c4c; /* Darker teal for the active state */
}

/* Hamburger menu icon */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px;
}

.standout-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 30rem;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
}

.simple-card {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
}

.list-item-img {
  height: 121px;
  width: 111px;
  object-fit: contain;
}

.list-item-title {
  font-family: 'Cinzel', serif;
}

.list-item-text{
  font-family: 'Lato', sans-serif;
  font-weight: 300; /* Less bold, lighter weight */
}

/* Responsive styling */
@media (max-width: 768px) {
  .navbar-title {
    font-size: 3rem;
  }

  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #ddd;
    width: 200px;
  }

  .navbar-links.active {
    z-index: 2;
    display: flex;
  }

  .hamburger {
    display: block;
    position: absolute;
    top: 2.0rem;
    right: 1rem;
  }
}

/* Responsive styling */
@media (max-width: 564px) {

  .hamburger {
    top: 1.91rem;
  }
}

/* Carousel image styling */
.carousel-image {
  height: 70vh;
  width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  position: relative;
}

/* Thumbnail dots */
.custom-thumbnail-dots {
  display: flex !important;
  overflow-x: auto; /* Enable horizontal scroll */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.custom-thumbnail-dots {
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.thumbnail-image {
  width: 3.75rem;
  height: 3.75rem;
  object-fit: cover;
  cursor: pointer;
  display: block;
  border: 0.125rem solid black;
  margin: 0 0.5rem;
}

.custom-thumbnail-dots .slick-active img {
  border: 2px solid #008080;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  filter: brightness(1.1);
}

/* Carousel arrows */
.slick-prev,
.slick-next {
  font-size: 2rem;
  color: #333;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;;
}

.slick-prev {
  left: 1rem;
}

.slick-next {
  right: 1rem;
}

.slick-prev:before,
.slick-next:before {
  color: #333;
  font-size: 1.5rem;
}

.slick-slide {
  overflow: visible !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.10);
  }
  100% {
    transform: scale(1);
  }
}

.frame {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Offset-x, offset-y, blur-radius, color */
  border-radius: 4px; /* Optional: Add rounded corners */
  border: 3px solid #333; /* Change the color and width to match your theme */
}

.pulse {
  transition: transform 0.3s ease;
  animation: pulse 50s infinite;
}
.grayscale {
  filter: grayscale(100%);
}

.accordion {
  width: 80%;
  max-width: 500px;
  margin: 0 auto; /* Center the accordion on the page */
}

.accordion-body {
  text-align: left; /* Align text to the left */
}

/* timer related css */
.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
